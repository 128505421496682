<template>
  <div class="group">
    <TopNavBar2 />
    <Carousel :carousels="carousels" />
    <div class="designIdea_box">
      <Title title="设计理念" />
      <div class="designIdeas">
        <div v-for="(item, index) in designIdeas" :key="index" class="item">
          <p v-for="(item2, index2) in item.title" :key="index2">{{ item2 }}</p>
        </div>
      </div>
    </div>
    <div class="buildIdea_box">
      <Title title="建设理念" />
      <div class="buildIdea">
        <div class="left">
          <div>
            <p>5度</p>
            <p>建设理念</p>
          </div>
        </div>
        <div class="buildIdeas">
          <div v-for="(item, index) in buildIdeas" :key="index" class="item">
            {{ item.title }} <span>—</span> {{ item.title2 }}
          </div>
        </div>
      </div>
    </div>
    <div class="direction_box">
      <Title title="建设方向" />
      <div class="top_img">
        <div v-for="(item, index) in directions" :key="index" class="item">
          <p>{{ item.title }}</p>
          <p>{{ item.title2 }}</p>
        </div>
        <div class="middle">4大建设方向</div>
      </div>
      <div class="directions">
        <div v-for="(item, index) in directions" :key="index" class="item">
          <div class="title">
            <img :src="item.imgUrl" alt="" />
            <h3>{{ item.title }}{{ item.title2 }}</h3>
          </div>
          <p class="content">{{ item.content }}{{ item.content2 }}</p>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="framework_box">
        <Title title="架构图" />
        <div class="framework">
          <img src="@/assets/images/product/group/bg2.png" alt="" />
        </div>
      </div>
    </div>
    <Footer2 />
  </div>
</template>

<script>
export default {
  name: "Group",
  data() {
    return {
      designIdeas: [
        {
          title: ["综合全面支撑业务"],
        },
        {
          title: ["应用架构", "层次清晰"],
        },
        {
          title: ["可视集团全维管控"],
        },
        {
          title: ["应用操作", "便捷智能"],
        },
        {
          title: ["持续沉浸式", "交互应用"],
        },
      ],
      buildIdeas: [
        { title: "简化部门操作", title2: "智能减负提效" },
        { title: "基于数据管理", title2: "数据驱动决策" },
        { title: "融合多端应用", title2: "内整外接融合" },
        { title: "汇聚数据资源", title2: "打造数据资产" },
        { title: "集化管理运维", title2: "一屏一图一表" },
      ],
      directions: [
        {
          title: "业务",
          title2: "场景化",
          content: "数字化协调-业务全场景智能协同",
          content2: "（基础业务场景构建业务协同）",
          imgUrl: require("@/assets/images/product/group/icon1.png"),
        },
        {
          title: "宣传",
          title2: "阵地化",
          content: "数字化展览-集团文化全天候展示",
          content2: "（建立数字文化宣传阵地）",
          imgUrl: require("@/assets/images/product/group/icon2.png"),
        },
        {
          title: "决策",
          title2: "数据化",
          content: "数字化决策-集群数据可视化资产",
          content2: "（基于全维数据推动管理科学化）",
          imgUrl: require("@/assets/images/product/group/icon3.png"),
        },
        {
          title: "应用",
          title2: "智能化",
          content: "智能化应用-数字孪生可视化操作",
          content2: "（基于智能技术和算法提升应用操作）",
          imgUrl: require("@/assets/images/product/group/icon4.png"),
        },
      ],
      carousels: [
        {
          imgUrl: require("@/assets/images/carousels/12.png"),
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.group {
  .box {
    display: flex;
    justify-content: center;
  }
  .designIdea_box {
    margin-top: 20px;
    .designIdeas {
      position: relative;
      width: 156px;
      height: 158px;
      margin: 50px auto 0;
      background-image: url(~@/assets/images/product/group/design_bg.png);
      background-size: cover;
      .item {
        position: absolute;
        > p {
          font-family: "AlibabaPuHuiTi-Light";
          font-weight: 200;
          font-size: 15px;
          color: #333;
          line-height: 20px;
        }
      }
      .item:nth-child(1) {
        top: -25px;
        left: -16px;
      }
      .item:nth-child(2) {
        top: 25px;
        right: -72px;
      }
      .item:nth-child(3) {
        bottom: -20px;
        left: -28px;
      }
      .item:nth-child(4) {
        bottom: 12px;
        right: -58px;
      }
      .item:nth-child(5) {
        left: -58px;
        top: 48px;
        text-align: right;
      }
    }
  }
  .buildIdea_box {
    margin-top: 60px;
    padding: 15px 10px 0;
    height: 235px;
    background: linear-gradient(162.597009969011deg, #ffffff 14%, #ddeaff 87%);
    .buildIdea {
      margin-top: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      .left {
        position: relative;
        width: 148px;
        height: 136px;
        background-image: url(~@/assets/images/product/group/bg1.png);
        background-size: cover;
        background-repeat: no-repeat;
        > div {
          position: absolute;
          left: 20px;
          top: 40px;
          p {
            font-family: "AlibabaPuHuiTi-Regular";
            font-weight: 400;
            font-size: 15px;
            color: #ffffff;
            line-height: 20px;
          }
        }
      }
      .buildIdeas {
        width: 200px;
        height: 180px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .item {
          box-shadow: 0px 0px 5px #66666659;
          line-height: 30px;
          background-color: #ffffff;
          box-sizing: border-box;
          border: 1px solid #3e64ef;
          border-radius: 2px;
          font-weight: 200;
          font-size: 13px;
          color: #333333;
          font-family: "AlibabaPuHuiTi-Light";
        }
      }
    }
  }
  .direction_box {
    margin-top: 40px;
    .top_img {
      position: relative;
      margin: 20px auto 0;
      width: 297px;
      height: 297px;
      background-image: url(~@/assets/images/product/group/build_bg.png);
      background-repeat: no-repeat;
      background-size: cover;
      .middle {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-family: "AlibabaPuHuiTi-Regular";
        font-weight: 400;
        font-size: 15px;
        color: #ffffff;
        text-align: center;
        line-height: 20px;
      }
      .item {
        position: absolute;
        width: 80px;
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {
          font-size: 15px;
          line-height: 20px;
          font-weight: 200;
          font-family: "AlibabaPuHuiTi-Light";
        }
      }
      .item:nth-child(1),
      .item:nth-child(2) {
        top: 26px;
      }
      .item:nth-child(3),
      .item:nth-child(4) {
        bottom: 26px;
      }
      .item:nth-child(1),
      .item:nth-child(3) {
        left: 18px;
      }
      .item:nth-child(2),
      .item:nth-child(4) {
        right: 18px;
      }
    }
    .directions {
      padding: 0 16px;
      display: flex;
      flex-direction: column;
      .item {
        display: flex;
        flex-direction: column;
        .content {
          font-family: "AlibabaPuHuiTi-Light";
          font-weight: 200;
          font-size: 14px;
          line-height: 19px;
          color: #333;
          text-align: left;
        }
        .title {
          margin: 0 0 5px;
          display: flex;
          align-items: center;
          img {
            width: 15px;
            height: 15px;
          }
          h3 {
            margin-left: 5px;
            line-height: 20px;
            font-family: "AlibabaPuHuiTi-Regular";
            font-weight: 400;
            font-size: 15px;
            color: #333;
          }
        }
      }
      .item + .item {
        margin-top: 20px;
      }
    }
  }
  .framework_box {
    margin: 50px 0 32px;
    .framework {
      margin-top: 20px;
      width: 345px;
      height: 268px;
      background-color: #ffffff;
      box-shadow: 0px 0px 30px #eeeeeeb3;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 325px;
        height: 248px;
      }
    }
  }
}
</style>
